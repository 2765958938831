<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="姓名">
                <el-input v-model="search.xingMing" size="small"/>
            </x-search-item>
            <x-search-item label="手机号">
                <el-input v-model="search.shouJiHao" size="small"/>
            </x-search-item>
            <x-search-item label="加入时间" textWidth="250px" hasEnd>
                <el-date-picker v-model="search.jiaRuSJBegin" size="small" type="datetime"/>
                <el-date-picker v-model="search.jiaRuSJEnd" size="small" type="datetime" slot="end"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 批量按钮区 -->
        <div class="x-page-btn">
            <el-button type="success" size="mini" round @click="handleAdd">新增</el-button>
            <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDeleteBatch">
                <el-button type="danger" size="mini" round slot="reference">批量删除</el-button>
            </el-popconfirm>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                  @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="40" fixed/>
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column width="200" prop="xingMing" label="姓名"/>
            <el-table-column width="150" prop="shouJiHao" label="手机号"/>
            <el-table-column width="150" prop="jiaRuSJ" label="加入时间"/>
            <el-table-column label="权限">
                <template slot-scope="{ row, $index }">
                    <div class="qxDiv">
                        <div class="spanDiv">
                            <span class="leftSpan">退款权限:</span>
                            <span>{{isOpen(row.shouYinQuanXianXX.tuiKuanQuanXian)}}</span>
                        </div>
                        <div class="spanDiv">
                            <span class="leftSpan">充值权限:</span>
                            <span>{{isOpen(row.shouYinQuanXianXX.chongZhiQuanXian)}}</span>
                        </div>
                        <div class="spanDiv">
                            <span class="leftSpan">编辑会员:</span>
                            <span>{{isOpen(row.shouYinQuanXianXX.bjHyzlQx)}}</span>
                        </div>
                    </div>
                    <div class="qxDiv">
                        <div class="spanDiv">
                            <span class="leftSpan">赠送金额:</span>
                            <span>{{isOpen(row.shouYinQuanXianXX.syzdyHyzsJinEQX)}}</span>
                        </div>
                        <div class="spanDiv">
                            <span class="leftSpan">销售明细:</span>
                            <span>{{isOpen(row.shouYinQuanXianXX.daYinXiaoShouMX)}}</span>
                        </div>
                        <div class="spanDiv">
                            <span class="leftSpan">结算打折:</span>
                            <span>{{isOpen(row.shouYinQuanXianXX.shouYinJiJieSuanZhengDanDaZhe)}}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column width="400" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button type="success" size="mini" round @click="qxUpdate(row)">权限</el-button>
                    <el-button type="primary" size="mini" round @click="handleUpdate(row.id)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <qx-edit ref="qx" :editor="editor" @submitted="refresh"/>
    </div>
</template>
<script>
    import * as service from "@/service/szgl/ShouYinYuan";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/szgl/jssz/ShouYinYuanEdit";
    import QxEdit from "@/view/szgl/jssz/ShouYinYuanQxEdit";
    import {DICT_OPEN_OR_CLOSE} from "@/util/constant";

    export default {
        name: "ShouYinYuanList",
        mixins: [XTableBase],
        components: {Edit,QxEdit},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                search: {
                    xingMing: '',
                    shouJiHao: '',
                    jiaRuSJBegin: '',
                    jiaRuSJEnd: '',
                }
            }
        },
        computed: {
            isOpen() {
                return function (val) {
                    if (DICT_OPEN_OR_CLOSE.OPEN.key === val) {
                        return DICT_OPEN_OR_CLOSE.OPEN.name
                    } else {
                        return DICT_OPEN_OR_CLOSE.CLOSE.name
                    }
                }
            }
        },
        methods:{
            qxUpdate(row) {
                this.$refs.qx.qxEditDisabled = true
                this.$refs.qx.syyObj = row
            },
        }
    }
</script>

<style scoped>

    .qxDiv{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    .leftSpan{
        color:#999;
    }
    .spanDiv{
        margin-right: 20px;
    }
</style>
