import request from '@/util/request'

export function list(data) {
    return request({
        url: '/role/list',
        method: 'post',
        data
    });
}

export function getUpdate(id) {
    return request({
        url: `/role/get-update/${id}`,
        method: 'get',
    })
}

export function add(data) {
    return request({
        url: '/role/add',
        method: 'post',
        data
    })
}

export function update(data) {
    return request({
        url: '/role/update',
        method: 'post',
        data
    })
}

export function del(id) {
    return request({
        url: `/role/del/${id}`,
        method: 'post'
    })
}

export function getRoleOptions() {
    return request({
        url: '/role/get-role-options',
        method: 'get'
    })
}
