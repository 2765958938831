<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="dialogOpen" :close-on-click-modal="false"
                destroy-on-close center append-to-body width="500px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="所属门店" prop="suoShuMenDian" class="line">
                <el-input :readonly="defaultReadOnly" v-model="form.suoShuMenDian" size="small" maxlength="12" show-word-limit/>
            </el-form-item>
            <el-form-item label="姓名" prop="xingMing" class="line">
                <el-input v-model="form.xingMing" size="small" maxlength="12" show-word-limit/>
            </el-form-item>
            <el-form-item label="手机号" prop="shouJiHao" class="line">
                <el-input v-model="form.shouJiHao" size="small" maxlength="11" show-word-limit/>
            </el-form-item>
            <el-form-item label="加入时间" prop="jiaRuSJ" class="line">
                <el-date-picker  v-model="form.jiaRuSJ" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
            <el-form-item label="密码" prop="miMa" class="line">
                <el-input show-password :readonly="isReadonly" v-model="form.miMa" size="small" maxlength="8" show-word-limit/>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button :disabled="saveDisabled" type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/szgl/ShouYinYuan";
    import {getRoleOptions} from "@/service/system/role";
    import {getNowMenDian} from "@/service/mdgl/MenDianXX";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';
    import {deepCopy, deepMerge} from "@/util/objects"
    import {DICT_USE} from "@/util/constant";

    export default {
        mixins: [XEditBase],
        components:{},
        beforeCreate() {
            let {required} = ruleBuilder;
            this.rules = {
                xingMing: [required()], // 姓名
                yuanGongId: [], // 员工Id
                shouJiHao: [required()], // 手机号
                jiaRuSJ: [required()], // 加入时间
                suoShuMenDian: [], // 所属门店
                suoShuMenDianId: [required()], // 所属门店Id
                miMa: [required()], // 密码
            }
            this.titlePrefix = '收银员';
            this.defaultForm = {
                id: null,
                xingMing: "", // 姓名
                yuanGongId: "", // 员工Id
                shouJiHao: "", // 手机号
                jiaRuSJ: "", // 加入时间
                suoShuMenDian: "", // 所属门店
                suoShuMenDianId: "", // 所属门店Id
                miMa: "", // 密码
                name: "", // 角色名称
                account: "", // 标题
                phone: "", // 描述
                orgCode: "",
                organName: "",
                deptCode: "",
                deptName: "",
                status: "enable",
                userOrganId: "",
                roleIds: [],
                userGroupIds: [],
                securityClassification: "0",
                classification: "0",
                sldd: "001",
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        },
        data() {
            return {
                roles: [],
                options:[],
                saveDisabled:false,
                defaultReadOnly:true,
                shouYinQuanXianXX: {
                    tuiKuanQuanXian: DICT_USE.DISABLE.key, // 退款权限
                    tuiKuanQuanXianMa: "", // 退款权限码
                    chongZhiQuanXian: DICT_USE.DISABLE.key, // 充值权限
                    chongZhiQuanXianMa: "", // 充值权限码
                    bjHyzlQx: DICT_USE.DISABLE.key, // 编辑会员资料权限
                    bjHyzlQxm: "", // 编辑会员资料权限码
                    addOrEditToPlus: DICT_USE.DISABLE.key, // 新增/编辑常规会员成为plus会员权限
                    syzdyHyzsJinEQX: DICT_USE.DISABLE.key, // 收银自定义会员赠送金额权限
                    daYinXiaoShouMX: DICT_USE.ENABLE.key, // 打印销售明细
                    daYinCiShu: 1, // 打印次数
                    shouYinJiJieSuanZhengDanDaZhe: DICT_USE.DISABLE.key, // 收银机结算整单打折
                    chaKanSuoYouDingDan: DICT_USE.ENABLE.key, // 查看所有订单
                    tianJiaShangPin: DICT_USE.ENABLE.key, // 添加商品
                    danPinShouDongZheKou: DICT_USE.DISABLE.key, // 单品手动折扣
                    zuiDiZheKouZhi: "", // 最低折扣值
                    zuiGaoYouHuiJinE: "", // 最高优惠金额
                    shouYinYuanZengSongShangPin: DICT_USE.DISABLE.key, // 收银员赠送商品
                    danBiZuiGaoKeZengSongSL: "", // 单笔最高可赠送数量
                    shouYinJiChuanCheng: DICT_USE.ENABLE.key, // 收银机传称
                    shouYinJiShangPinGaiJia: DICT_USE.DISABLE.key, // 收银机商品改价
                    shangPinGuanLiQuanXian: DICT_USE.DISABLE.key, // 商品管理权限
                    bianJiShangPinQuanXian: DICT_USE.DISABLE.key, // 编辑商品权限
                    shanChuShangPinQuanXian:DICT_USE.DISABLE.key,// 删除商品权限
                    piLiangXiuGaiShangPin: DICT_USE.DISABLE.key, // 批量修改商品
                    tianJiaWuMaShangPin: DICT_USE.DISABLE.key, // 添加无码商品
                    shanChuLiXianShuJu: DICT_USE.DISABLE.key, // 删除离线数据
                    chaKanQiTaMenDianKuCun: DICT_USE.DISABLE.key, // 查看其他门店库存
                    shouYinMoLingQuanXian: DICT_USE.DISABLE.key, // 收银抹零权限
                },
            }
        },
        computed:{
            isReadonly(){
                if(this.form.xingMing){
                    return false
                }
                return true
            }
        },
        methods:{
            getMenDian(){
                getNowMenDian().then((res)=>{
                    this.form.suoShuMenDian = res.data.menDianMC
                    this.form.suoShuMenDianId = res.data.id
                })
            },
            dialogOpen(){
                //验证是编辑还是添加并处理
                if (this.isUpdate) {
                    return this.getUpdateService(this.editor.id).then((response) => {
                        this.form = deepMerge(this.defaultForm, response.data);
                        //清空校验
                        this._clearValidate();
                        return response;
                    })
                } else {
                    this.form = deepCopy(this.defaultForm);
                    this.form.shouYinQuanXianXX = this.shouYinQuanXianXX
                    this.getMenDian()
                    getRoleOptions().then((response) => {
                        if(response.data){
                            let role = response.data.find(item=>item.name=='收银员')
                            if(role){
                                this.form.roleIds.push(role.id)
                            }else{
                                this.saveDisabled = true
                                this.$message.error("请先在角色管理添加配置名称为：收银员 的相关角色信息！");
                            }
                        }
                    });
                    //清空校验
                    this._clearValidate();
                    return Promise.resolve({code: 200});
                }

            },
        }
    }
</script>

<style scoped>

</style>
