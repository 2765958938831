<template>
    <el-dialog title="权限设置" :visible.sync="qxEditDisabled" @open="open"
                destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="退款权限" prop="tuiKuanQuanXian" class="w50">
                <x-switch v-model="form.tuiKuanQuanXian" dictType="T_USE"/>
            </el-form-item>
            <el-form-item label="权限码" prop="tuiKuanQuanXianMa" class="w50" :rules="isOpen(this.form.tuiKuanQuanXian)?rules.tuiKuanQuanXianMa:[]">
                <el-input v-model="form.tuiKuanQuanXianMa" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="充值权限" prop="chongZhiQuanXian" class="w50">
                <x-switch v-model="form.chongZhiQuanXian" dictType="T_USE"/>
            </el-form-item>
            <el-form-item label="权限码" prop="chongZhiQuanXianMa" class="w50" :rules="isOpen(this.form.chongZhiQuanXian)?rules.chongZhiQuanXianMa:[]">
                <el-input v-model="form.chongZhiQuanXianMa" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="编辑会员资料权限" prop="bjHyzlQx" class="w50">
                <x-switch v-model="form.bjHyzlQx" dictType="T_USE"/>
            </el-form-item>
            <el-form-item label="权限码" prop="bjHyzlQxm" class="w50" :rules="isOpen(this.form.bjHyzlQx)?rules.bjHyzlQxm:[]">
                <el-input v-model="form.bjHyzlQxm" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="打印销售明细" prop="daYinXiaoShouMX" class="w50">
                <x-switch v-model="form.daYinXiaoShouMX" dictType="T_USE"/>
            </el-form-item>
            <el-form-item label="打印次数" prop="daYinCiShu" class="w50">
                <el-input v-model="form.daYinCiShu" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="收银员赠送商品" prop="shouYinYuanZengSongShangPin" class="w50">
                <x-switch v-model="form.shouYinYuanZengSongShangPin" dictType="T_USE"/>
            </el-form-item>
            <el-form-item label="单笔最高可赠送数量" prop="danBiZuiGaoKeZengSongSL" class="w50" :rules="isOpen(this.form.shouYinYuanZengSongShangPin)?rules.danBiZuiGaoKeZengSongSL:[]">
                <el-input v-model="form.danBiZuiGaoKeZengSongSL" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="单品手动折扣" prop="danPinShouDongZheKou">
                <x-switch v-model="form.danPinShouDongZheKou" dictType="T_USE"/>
            </el-form-item>
            <el-form-item label="最低折扣值" prop="zuiDiZheKouZhi" :rules="isOpen(this.form.danPinShouDongZheKou)?rules.danBiZuiGaoKeZengSongSL:rules.zdzkz">
                <el-input v-model="form.zuiDiZheKouZhi" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="最高优惠金额" prop="zuiGaoYouHuiJinE" :rules="isOpen(this.form.danPinShouDongZheKou)?rules.danBiZuiGaoKeZengSongSL:rules.zgyhje">
                <el-input v-model="form.zuiGaoYouHuiJinE" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="新增/编辑常规会员成为plus会员权限" prop="addOrEditToPlus">
                <x-switch v-model="form.addOrEditToPlus" dictType="T_USE"/>
            </el-form-item>
            <el-form-item label="收银自定义会员赠送金额权限" prop="syzdyHyzsJinEQX">
                <x-switch v-model="form.syzdyHyzsJinEQX" dictType="T_USE"/>
            </el-form-item>
            <el-form-item label="收银机结算整单打折" prop="shouYinJiJieSuanZhengDanDaZhe">
                <x-switch v-model="form.shouYinJiJieSuanZhengDanDaZhe" dictType="T_USE"/>
            </el-form-item>
            <el-form-item label="查看所有订单" prop="chaKanSuoYouDingDan">
                <x-switch v-model="form.chaKanSuoYouDingDan" dictType="T_USE"/>
            </el-form-item>
            <el-form-item label="添加商品" prop="tianJiaShangPin">
                <x-switch v-model="form.tianJiaShangPin" dictType="T_USE"/>
            </el-form-item>
            <el-form-item label="收银机传称" prop="shouYinJiChuanCheng">
                <x-switch v-model="form.shouYinJiChuanCheng" dictType="T_USE"/>
            </el-form-item>
            <el-form-item label="收银机商品改价" prop="shouYinJiShangPinGaiJia">
                <x-switch v-model="form.shouYinJiShangPinGaiJia" dictType="T_USE"/>
            </el-form-item>
            <el-form-item label="商品管理权限" prop="shangPinGuanLiQuanXian">
                <x-switch v-model="form.shangPinGuanLiQuanXian" dictType="T_USE"/>
            </el-form-item>
            <el-form-item label="编辑商品权限" prop="bianJiShangPinQuanXian">
                <x-switch v-model="form.bianJiShangPinQuanXian" dictType="T_USE"/>
            </el-form-item>
            <el-form-item label="删除商品权限" prop="shanChuShangPinQuanXian">
                <x-switch v-model="form.shanChuShangPinQuanXian" dictType="T_USE"/>
            </el-form-item>
            <el-form-item label="批量修改商品" prop="piLiangXiuGaiShangPin">
                <x-switch v-model="form.piLiangXiuGaiShangPin" dictType="T_USE"/>
            </el-form-item>
            <el-form-item label="添加无码商品" prop="tianJiaWuMaShangPin">
                <x-switch v-model="form.tianJiaWuMaShangPin" dictType="T_USE"/>
            </el-form-item>
            <el-form-item label="删除离线数据" prop="shanChuLiXianShuJu">
                <x-switch v-model="form.shanChuLiXianShuJu" dictType="T_USE"/>
            </el-form-item>
            <el-form-item label="查看其他门店库存" prop="chaKanQiTaMenDianKuCun">
                <x-switch v-model="form.chaKanQiTaMenDianKuCun" dictType="T_USE"/>
            </el-form-item>
            <el-form-item label="收银抹零权限" prop="shouYinMoLingQuanXian">
                <x-switch v-model="form.shouYinMoLingQuanXian" dictType="T_USE"/>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {updateQx} from "@/service/szgl/ShouYinYuan";
    import XEditBase from "@/components/x/XEditBase";
    import {deepCopy, deepMerge} from "@/util/objects"
    import {ruleBuilder} from '@/util/validate';
    import {DICT_USE} from "@/util/constant";

    export default {
        mixins: [XEditBase],
        beforeCreate() {
            let {required,digits,positiveZeroTwo,positiveEightTwo} = ruleBuilder;
            this.rules = {
                tuiKuanQuanXianMa:[required('string', ['change', 'blur']),digits()],
                chongZhiQuanXianMa:[required('string', ['change', 'blur']),digits()],
                bjHyzlQxm:[required('string', ['change', 'blur']),digits()],
                daYinCiShu:[required('string', ['change', 'blur']),digits()],
                danBiZuiGaoKeZengSongSL:[required('string', ['change', 'blur']),digits()],
                zuiDiZheKouZhi:[required('string', ['change', 'blur']),positiveZeroTwo()],
                zuiGaoYouHuiJinE:[required('string', ['change', 'blur']),positiveEightTwo()],
                zdzkz:[positiveZeroTwo()],
                zgyhje:[positiveEightTwo()]

            }
        },
        data(){
            return {
                syyObj:{},
                qxEditDisabled:false,
                form:{}
            }
        },
        computed:{
            isOpen(){
                return function (val) {
                    return DICT_USE.ENABLE.key===val
                }
            }
        },
        methods:{
            open(){
                this.form=deepCopy(this.syyObj.shouYinQuanXianXX)
            },
            cancel(){
                this.qxEditDisabled = false
            },
            async save(callback) { // 通用save方法 增加回调
                this.loading = true;
                let isvalidate = false;
                try {
                    await this.$refs.form.validate((valid, message) => {
                        if (valid) { // 如果校验通过则提交
                            isvalidate = true;
                        } else { // 校验不通过
                            this.$message.error("有必填项没有填写请检查！");
                            this.loading = false;
                        }
                    });
                } catch (e) {
                    this.loading = false;
                    return;
                }
                if (!isvalidate) return;
                let commitForm = {
                    id:this.syyObj.id,
                    shouYinQuanXianXX:this.form
                }
                const response = await updateQx(commitForm).finally(() => {
                    this.loading = false; //TODO 更好的处理保存按钮加载问题
                });//执行保存方法
                if (callback && typeof callback === 'function') {
                    callback(response);
                }
                //打印提示信息
                this.$message.success(response.msg);
                this.$emit("submitted", response);
                this.cancel();
            },
        }
    }
</script>

<style scoped>

</style>
